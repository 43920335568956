import React from "react";
import Common from "./Common";
import web from "../src/Pictures/img0.png";

function Home() {
  return (
    <>
      <Common
        name="助力产品爆量"
        imgsrc={web}
        visit="/service"
        btname="开始"
      />
    </>
  );
}

export default Home;
