import React from "react";
import Common from "./Common";
import web from "../src/Pictures/img3.png";

function About() {
  return (
    <>
      <Common
        name="休闲游戏行业佼佼者"
        imgsrc={web}
        visit="/contact"
        btname="联系我们"
      />
    </>
  );
}

export default About;
