import React from "react";
import { Layout } from "antd";
import { CopyrightOutlined } from "@ant-design/icons";

export default function Footer() {
  return (
    <Layout.Footer style={{ padding: 0 }}>
      <div className="footer">
        <div style={{ color: "rgba(0, 0, 0, .45)" }}>
          Copyright <CopyrightOutlined /> {new Date().getFullYear()} All Rights Reserved. 创世方舟 版权所有 
          <a style={{textDecoration:"none"}} href="http://beian.miit.gov.cn/"> 蜀ICP备2022004218号</a>
          {/* <a style={{textDecoration:"none"}} href="http://beian.miit.gov.cn/"> 蜀ICP备18038460号-1</a> */}
          {/* <a style={{textDecoration:"none"}} href="http://beian.miit.gov.cn/"> 蜀ICP备18038460号-2</a> */}
        </div>
        <div style={{ color: "rgba(0, 0, 0, .45)" }}>
          {/* 地址：中国（四川）自由贸易试验区成都市高新区吉庆一路176号1栋16楼1606号 */}
          地址：中国（四川）自由贸易试验区成都高新区吉庆一路176号1栋17楼1707号
        </div>
      </div>
    </Layout.Footer>
  );
}
